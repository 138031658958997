import Immutable from 'seamless-immutable';
import pathOr from 'ramda/src/pathOr';
import mergeDeepLeft from 'ramda/src/mergeDeepLeft';
import groupBy from 'ramda/src/groupBy';
import {OFFER_STATUS_INDICATOR_STATUS} from 'invision-core/src/constants/status.constants';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    MetadataCodeSelector,
    MetadataCodeTypeDictionarySelector,
    MetadataCodeTypeIntegerSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {DiscountsMetadataMapSelector} from 'invision-core/src/components/metadata/discounts/discounts.selectors';
import {getBoolOrDefault} from 'invision-core/src/components/helpers/bool.helper';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {OfferingMetadataPricingPlansSelector} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {
    BILLER_RULE_INSTANCE_TYPE,
    DEVICE_ADDON_SERVICE_ATTRIBUTE_ID
} from '../../customercare.constants';
import {
    HasSuspendResumePermissionSelector,
    IsServiceLifeCycleStatusUpdatableSelector,
    selectedCustomerBulkItemsSelector,
    ServicesWithFormattedServiceAttributeSelector,
} from './services.selectors';
import {IsCurrentAccountPostpaidSelector} from './customer.convergent.biller.selectors';
import {
    ChangeOfferEligibilityRulesSelector,
    IsCoolingPeriodEnabledSelector
} from './customer.offerings.selectors';
import {
    getActiveBillerTypeAmounts,
    getDiscountAndBriDetails,
    getServiceMoreActionMenuItems
} from './selected.service.details.selectors.helper';
import {
    filterBulkOfferOptions,
    getOptionsByActiveBriType,
    hasPendingChange as hasOfferPendingChanges,
    isPaymentInstrumentChangeable
} from '../../components/customer/dashboard/dbssDashboard/offering/offering.component.helper';
import {getMoreMenuItems} from './dashboard.selectors.helper';
import {getFormattedServiceAttributeValue} from './services.list.selectors.helper';
import {OFFERING_OPTION_STATUSES} from '../../components/shared/constants/offering.option.status.constants';
import clone from 'ramda/src/clone';
import {SelectedSubscriberOfferingInstanceIdSelector} from './search.subscriber.offerings.selectors';
import {IsPIIDataAccessEnabledSelector} from './customer.selectors';

const EMPTY_ARRAY = Immutable([]);

export const SelectedServiceIdentifierSelector = createImmutableSelector(
    [selectedCustomerBulkItemsSelector, ServicesWithFormattedServiceAttributeSelector],
    (bulkItems, services) => {
        const serviceIdentifier = pathOr(null, ['data', 'selectedServiceIdentifier'], bulkItems);
        return serviceIdentifier || pathOr(null, ['serviceThumbnails', '0', 'ServiceIdentifier'], services);
    }
);

export const SelectedServiceThumbnailSelector = createImmutableSelector(
    [SelectedServiceIdentifierSelector, ServicesWithFormattedServiceAttributeSelector],
    (serviceIdentifier, services) => {
        return (services.serviceThumbnails || EMPTY_ARRAY).find((service) => {
            return service.ServiceIdentifier === serviceIdentifier;
        });
    }
);

export const IsFetchingServiceDetailsSelector = createImmutableSelector(
    [selectedCustomerBulkItemsSelector],
    (bulkItems) => {
        return bulkItems.isFetchingServiceDetails;
    }
);

export const SelectedServiceDetailsSelector = createImmutableSelector(
    [
        SelectedServiceIdentifierSelector,
        selectedCustomerBulkItemsSelector,
        HasSuspendResumePermissionSelector,
        IsServiceLifeCycleStatusUpdatableSelector,
        MetadataCodeSelector(CODES.ServiceLifeCycleStatus),
        MetadataCodeSelector(CODES.UnitsOfMeasure),
        IsPIIDataAccessEnabledSelector
    ],
    (serviceIdentifier, bulkItems, hasSuspendResumeAccess, isServiceLifeCycleStatusUpdatable, serviceLifeCycleStatusCodes, units, isPIIDataAccessEnabled) => {
        const serviceDetails = pathOr(EMPTY_ARRAY, ['data', 'selectedServices', 'data'], bulkItems).find((service) => {
            return service.ServiceIdentifier.Value === serviceIdentifier;
        });

        return serviceDetails && Object.assign({}, serviceDetails, {
            ServiceLifeCycleStatusDetails: serviceDetails.ServiceLifeCycleStatus ?
                pathOr(EMPTY_ARRAY, ['items'], serviceLifeCycleStatusCodes).find((lifeCycleStatus) => {
                    return lifeCycleStatus.Value === serviceDetails.ServiceLifeCycleStatus;
                }) : undefined,
            moreActionMenuItems: getServiceMoreActionMenuItems(serviceDetails.Status, serviceDetails.AccountDetailsOfferings[0].IsEditable,
                hasSuspendResumeAccess, isServiceLifeCycleStatusUpdatable, isPIIDataAccessEnabled),
            UsageCaps: (serviceDetails.UsageCaps || []).map(item => {
                const usageCapItem = clone(item);
                usageCapItem.ThresholdType.Name = units.items.find(unitItem => {
                    return unitItem.Value === item.ThresholdType.Code.toString();
                })?.Name;
                return usageCapItem;
            })
        });
    }
);

export const SelectedServiceDetailsOfferOrderedOffering = createImmutableSelector(
    [SelectedServiceDetailsSelector],
    (selectedServiceDetails) => {
        return selectedServiceDetails?.AccountDetailsOfferings?.[0];
    }
);

export const SelectedServiceDetailsOfferOrderedOfferingAdditionalPropertiesSelector = createImmutableSelector(
    [SelectedServiceDetailsOfferOrderedOffering],
    (accountDetailOffering) => {
        return accountDetailOffering?.OrderedOfferingAdditionalProperties || [];
    }
);

export const SelectedServicePolicyCountersSelector = createImmutableSelector(
    [SelectedServiceDetailsSelector],
    (selectedServiceDetails) => {
        let policyCounterList = pathOr([], ['PolicyCounters'], selectedServiceDetails);
        policyCounterList = policyCounterList.map((policyCounter) => {
            if (policyCounter.PolicyRange && policyCounter.PolicyRange.length) {
                const policyRange = policyCounter.PolicyRange.map((range) => {
                    return mergeDeepLeft(range, {
                        unitCode: policyCounter.BalanceUnitCode,
                        currentStatus: policyCounter.PolicyStatus
                    });
                });
                return mergeDeepLeft(policyCounter, {
                    policyRange
                });
            }
            return policyCounter;
        });
        const groupedPolicyCounter = groupBy((policyCounter) => {
            return policyCounter.ExternalPolicyIdentifier;
        });
        return groupedPolicyCounter(policyCounterList);
    });

export const SelectedServiceCurrencyCodeSelector = createImmutableSelector(
    [selectedCustomerBulkItemsSelector],
    (bulkItems) => {
        return pathOr(null, ['data', 'selectedServices', 'currencyCode'], bulkItems);
    }
);

export const SelectedServiceAssociatedDeviceFinanceDetailsSelector = createImmutableSelector(
    [SelectedServiceDetailsSelector],
    (service) => {
        return pathOr(null, ['SubscriberDeviceFinanceDetails', '0'], service);
    }
);

export const SelectedServiceAssociatedDeviceFinanceDiscountsSelector = createImmutableSelector(
    [SelectedServiceAssociatedDeviceFinanceDetailsSelector, DiscountsMetadataMapSelector, SelectedServiceCurrencyCodeSelector],
    (deviceFinancingDetails, allDiscounts, currency) => {
        let discounts = [];
        if (allDiscounts && deviceFinancingDetails && deviceFinancingDetails.FinanceDiscounts && deviceFinancingDetails.FinanceDiscounts.length) {
            discounts = deviceFinancingDetails.FinanceDiscounts.map(financeDiscount => {
                if (allDiscounts[financeDiscount.DiscountId]) {
                    return {
                        Name: allDiscounts[financeDiscount.DiscountId].StorefrontText || allDiscounts[financeDiscount.DiscountId].Name,
                        Currency: currency,
                        savings: financeDiscount.Amount
                    };
                }
            });
        }
        return discounts.filter(discount => {
            return discount;
        });
    }
);

export const SelectedServiceAssociatedOfferDetailsSelector = createImmutableSelector(
    [
        SelectedServiceDetailsSelector,
        IsDbss,
        IsCurrentAccountPostpaidSelector,
        MetadataCodeTypeIntegerSelector(CODES.TransitionConfiguration),
        ChangeOfferEligibilityRulesSelector,
        IsCoolingPeriodEnabledSelector,
        SelectedServiceCurrencyCodeSelector,
        DiscountsMetadataMapSelector,
        OfferingMetadataPricingPlansSelector,
        IsPIIDataAccessEnabledSelector
    ],
    (service, isDbss, isCurrentAccountPostpaid, transitionConfiguration, changeOfferEligibilityRules, isCoolingPeriodEnabledForBU, currencyCode, discountsMetadataMap, pricingPlanThumbnails, isPIIDataAccessEnabled) => {
        const accountDetailsOfferings = pathOr([], ['AccountDetailsOfferings'], service);
        const offerDetails = accountDetailsOfferings.find((accountDetailsOffering) => {
            return (accountDetailsOffering.Status !== OFFER_STATUS_INDICATOR_STATUS.REMOVED);
        }) || accountDetailsOfferings.find((accountDetailsOffering) => {
            return (accountDetailsOffering.Status === OFFER_STATUS_INDICATOR_STATUS.REMOVED && accountDetailsOffering.IsCoolingOff);
        });

        if (!offerDetails) {
            return null;
        }

        const offer = Object.assign({}, offerDetails, {
            Options: offerDetails.Options.filter((option) => {
                return option.BillerTypeAmounts && option.BillerTypeAmounts.length;
            })
        });

        const transitionProperties = pathOr(null, ['0', 'AdditionalProperties'], transitionConfiguration, false);
        const transitionFlagFromCodeTable = transitionProperties && transitionProperties.find((item) => {
            return item.Key === 'AllowTransitioning';
        });

        const allowTransitioning = getBoolOrDefault(transitionFlagFromCodeTable ? transitionFlagFromCodeTable.Value : undefined, false);
        const briOptions = getOptionsByActiveBriType(offer, PRODUCT_CLASSIFICATIONS.PRODUCT);
        const hasSubscriptions = briOptions[BILLER_RULE_INSTANCE_TYPE.SUBSCRIPTION].length;
        const hasPendingChange = hasOfferPendingChanges(offer);
        const bulkOffer = filterBulkOfferOptions(offer);
        const canChangePaymentInstrument = isPaymentInstrumentChangeable(offer);
        const isInGracePeriod = offer.Options.some((option) => {
            return option.OffCycleDetail &&
                option.OffCycleDetail.GracePeriodExpiryDate &&
                option.OffCycleDetail.CurrentRenewalFailedAttempts;
        });
        return Object.assign({}, offer, {
            moreOptionsMenuItems: getMoreMenuItems(
                hasSubscriptions,
                allowTransitioning,
                offer,
                bulkOffer,
                changeOfferEligibilityRules,
                canChangePaymentInstrument,
                isInGracePeriod,
                true,
                pricingPlanThumbnails,
                true,
                true,
                isPIIDataAccessEnabled
            ),
            Options: offer.Options.map(option => {
                return Object.assign({}, option, {
                    discountAndBriDetails: getDiscountAndBriDetails(option, currencyCode, discountsMetadataMap)
                });
            })
        });
    }
);

export const SelectedServiceAssociatedServiceFeaturesSelector = createImmutableSelector(
    [SelectedServiceDetailsSelector],
    (service) => {
        return (service && service.ServiceFeatures || EMPTY_ARRAY).filter((serviceFeature) => {
            return serviceFeature.Status !== OFFERING_OPTION_STATUSES.REMOVED;
        }).map((serviceFeature) => {
            return Object.assign({}, serviceFeature, {
                BillerTypeAmounts: getActiveBillerTypeAmounts(serviceFeature.BillerTypeAmounts)
            });
        });
    }
);

export const SelectedServiceAssociatedServiceAddonsSelector = createImmutableSelector(
    [SelectedServiceAssociatedServiceFeaturesSelector],
    (serviceFeatures) => {
        return (serviceFeatures || []).filter((serviceFeature) => {
            return !serviceFeature.ServiceAttributeValues.some((attribute) => {
                return attribute.ServiceAttributeId === DEVICE_ADDON_SERVICE_ATTRIBUTE_ID;
            });
        });
    }
);

export const SelectedServiceAssociatedDeviceAddonsSelector = createImmutableSelector(
    [SelectedServiceAssociatedServiceFeaturesSelector],
    (serviceFeatures) => {
        return (serviceFeatures || []).filter((serviceFeature) => {
            return serviceFeature.ServiceAttributeValues.some((attribute) => {
                return attribute.ServiceAttributeId === DEVICE_ADDON_SERVICE_ATTRIBUTE_ID;
            });
        });
    }
);

export const SelectedServiceAssociatedServiceIdSelector = createImmutableSelector(
    [SelectedServiceIdentifierSelector, SelectedServiceAssociatedOfferDetailsSelector],
    (serviceIdentifier, offer) => {
        return offer && offer.Options.reduce((serviceId, option) => {
            const selectedService = option.ServiceAttributeValues.find((service) => {
                return service.Value === serviceIdentifier;
            });
            serviceId = selectedService && selectedService.ServiceId ? selectedService.ServiceId : serviceId;

            return serviceId;
        }, EMPTY_ARRAY);
    }
);

export const SelectedServiceFormattedServiceIdentifierValueSelector = createImmutableSelector(
    [
        SelectedServiceDetailsSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (selectedService, serviceAttributes, regularExpressions) => {
        return selectedService && getFormattedServiceAttributeValue(
            selectedService.ServiceIdentifier.ServiceAttributeId,
            selectedService.ServiceIdentifier.Value,
            serviceAttributes,
            regularExpressions);
    }
);

export const SharedEntitlementBalancesWithFormattedServiceIdentifierSelector = createImmutableSelector(
    [
        SelectedServiceDetailsSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (selectedService, serviceAttributes, regularExpressions) => {
        return selectedService && selectedService.SharedEntitlementBalances.map((entitlement) => {
            const owningService = entitlement.EntitlementIdentifier && entitlement.EntitlementIdentifier.OwningServiceIdentifier;
            if (owningService) {
                entitlement = entitlement.setIn(
                    ['EntitlementIdentifier', 'OwningServiceIdentifier', 'FormattedValue'],
                    getFormattedServiceAttributeValue(owningService.ServiceAttributeId, owningService.Value, serviceAttributes, regularExpressions)
                );
            }
            return entitlement;
        }) || [];
    }
);